import { useState, useEffect, useCallback, RefObject } from 'react'

const useTypewriterOnScroll = (
  refs: RefObject<HTMLElement>[],
  messages: string[],
  styles: any,
  delays: number | number[] = 0,
) => {
  const [animatedIndices, setAnimatedIndices] = useState(new Set<number>())

  const typewriter = useCallback(
    (refIndex: number) => {
      if (animatedIndices.has(refIndex)) return

      setAnimatedIndices((prev) => {
        const newSet = new Set(prev)
        newSet.add(refIndex)
        return newSet
      })

      const node = refs[refIndex].current
      if (!node) return
      const text = messages[refIndex]
      const delay = Array.isArray(delays) ? delays[refIndex] : delays
      let i = 0

      function type() {
        if (i < text.length) {
          node.textContent += text.charAt(i)
          i++
          setTimeout(type, 23)
        } else {
          node.classList.remove(styles.caret)
          node.classList.remove(styles['initial-text'])
          node.classList.add(styles['final-text'])
        }
      }

      setTimeout(() => {
        node.textContent = ''
        node.classList.add(styles.caret)
        node.classList.add(styles['final-text'])
        type()
      }, delay)
    },
    [refs, messages, styles, animatedIndices, delays],
  )

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const refIndex = refs.findIndex((ref) => ref.current === entry.target)
          if (entry.isIntersecting && refIndex !== -1 && !animatedIndices.has(refIndex)) {
            typewriter(refIndex)
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0, // Adjust threshold if needed
      },
    )

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      })
    }
  }, [refs, typewriter, animatedIndices])

  return { typewriter }
}

export default useTypewriterOnScroll
