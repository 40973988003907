import styles from "@/components/mockups/home/hero/EVCheckPlusMockup.module.css";

import {useState, useEffect} from "react";
import {useRouter} from "next/router";
import {AnimatePresence, motion} from "framer-motion";
import ButtonComponent from "@/components/ui/buttons/v2/ButtonComponent";
import {BiMenu, BiX} from "react-icons/bi";

const VehicleSearch = () => {
  const router = useRouter();
  const [vrm, setVrm] = useState("");

  const handleSearch = () => {
    let url = "/ev-check";

    if (vrm) {
      const query = {};
      if (vrm) {
        query.vrn = vrm;
      }

      const queryString = new URLSearchParams(query).toString();
      url += `?${queryString}`;
    }

    router.push(url);
  };

  const handleGoToEvCheckPlus = () => {
    router.push("/ev-check");
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.newProduct}>🎉 New Product!</div>

      <div className={styles.searchContent}>
        <div className={styles.searchPage}>
          <div className={styles.pageHeader}>
            <div className={styles.logo}>
              <img className={styles.logoIcon} alt="" src="/logo_white.svg"/>
            </div>
            <div className={styles.menuBtn}>
              MENU <BiMenu size={12}/>
            </div>
          </div>

          <div className={styles.announcement}>
            <h2 className={styles.announcementTitle}>EV Check Plus</h2>
            <div className={styles.announcementSubtitle}>
              Take the stress out of buying a used electric vehicle with the ultimate insights report
            </div>
            <ButtonComponent
              title="Take me to the EV Check Plus page"
              variant="primary"
              onClick={handleGoToEvCheckPlus}
            />
          </div>

          <div className={styles.searchFormWrapper}>
            <div className={styles.searchForm}>
              <div className={styles.searchHeader}>
                <div className={styles.searchTitle}>Enter electric vehicle plate</div>
              </div>
              <div className={styles.searchBody}>
                <div className={styles.evPlateUK}>
                  <div className={styles.greenElement}></div>
                  <div className={styles.plateInputContainer}>
                    <input
                      id="vrm"
                      type="text"
                      className={styles.plateInput}
                      value={vrm}
                      onChange={(e) => setVrm(e.target.value.toUpperCase())}
                      placeholder="Enter Reg"
                    />
                  </div>
                </div>

                <ButtonComponent
                  title="Search"
                  variant="ghost"
                  onClick={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Summary = () => {
  return <div>2 Summary</div>;
};

const Payment = () => {
  return <div>3 Payment</div>;
};

const ViewReport = () => {
  return <div>4 ViewReport</div>;
};

const steps = [
  <VehicleSearch/>,
  /*  <Summary/>,
    <Payment/>,
    <ViewReport/>*/
];

const EVCheckPlusMockup = () => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentStep}
          initial={{opacity: 0, x: 50}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: -50}}
          transition={{duration: 0.5}}
          className={styles.motionDiv}
          style={{width: "100%", height: "100%"}}
        >
          {steps[currentStep]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default EVCheckPlusMockup;
