import styles from "@/components/home/product-section/product-previews/EVCheckPlusPreview.module.css";
import ButtonComponent from "@/components/ui/buttons/ButtonComponent";
import {default as V2ButtonComponent} from "@/components/ui/buttons/v2/ButtonComponent";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {useRouter} from "next/router";
import BlobScene from "@/utils/BlobScene";

const VehicleSearchSchema = Yup.object().shape({
  vrm: Yup.string()
    .transform((value) => value.replace(/\s/g, ""))
    .matches(
      /^[A-Z0-9]{3,7}$/,
      "Invalid vehicle registration number. It must be 3-7 alphanumeric characters.",
    )
    .required("Vehicle Registration Number is required"),
});

const EVCheckPlusPreview = () => {
  const initialValues = {
    vrm: "",
  };
  const router = useRouter();

  const handleSubmit = async (values) => {
    router.push(`ev-check?vrn=${values.vrm}`);
  };

  const openSampleL1Report = () => {
    window.open("https://clearwatt.co.uk/check/l1", "_blank");
    return null;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.blobContainer}>
          <BlobScene loading={0}/>
        </div>
        {/* <h1 className={styles.title}> */}
        {/* 	Take the stress out of buying a used EV <br /> */}
        {/* 	with the ultimate insights report */}
        {/* </h1> */}

        <Formik
          validationSchema={VehicleSearchSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({isSubmitting, isValid, touched}) => (
            <Form className={styles.form}>
              <div className={styles.subtitle}>Enter electric vehicle details</div>
              <div className={styles.evPlateUKContainer}>
                <div className={styles.evPlateUK}>
                  <div className={styles.greenElement}></div>
                  <div className={styles.plateInputContainer}>
                    <Field
                      className={styles.plateInput}
                      type="text"
                      id="vrm"
                      name="vrm"
                      placeholder="Enter Reg"
                      onInput={(e) => {
                        // Convert input to uppercase
                        e.target.value = e.target.value.toUpperCase();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.actions}>
                <V2ButtonComponent
                  type="submit"
                  disabled={!(isValid && touched?.vrm) || isSubmitting}
                  title="Search"
                  variant="ghost"
                />
              </div>
            </Form>
          )}
        </Formik>

        {/* <div className={styles.sampleReportButton}> */
        }
        {/* 	<V2ButtonComponent */
        }
        {/* 		variant="ghost" */
        }
        {/* 		title={"View a sample EV Check Plus"} */
        }
        {/* 		onClick={openSampleL1Report} */
        }
        {/* 	/> */
        }
        {/* </div> */
        }
        {/* <div className={styles.report}> */
        }
        {/* <img */
        }
        {/* 	className={styles.img} */
        }
        {/* 	src="/reports/L1.svg" */
        }
        {/* 	alt="EV Health Report" */
        }
        {/* /> */
        }
        {/* </div> */
        }
      </div>
    </>
  )
    ;
};

export default EVCheckPlusPreview;
